import React from "react"
import Theme from "../theme/theme"
import GlobalStyles from "../theme/globalStyles"
import Header from "./header/"
import { Flex, Box, Text, Link } from "rebass/styled-components"
import Div100vh from "react-div-100vh"

import background from "../images/navigation-background.jpg"
import LocationIcon from "../images/svgs/location"
import PhoneIcon from "../images/svgs/phone"

const Layout = ({ children, noScroll = false }) => (
  <Theme>
    <GlobalStyles />
    <Div100vh
      style={{
        height: noScroll ? "100rvh" : "auto",
        maxHeight: noScroll ? "100rvh" : "auto",
        minHeight: noScroll ? "auto" : "100vh",
        display: "flex",
      }}
    >
      <Flex flex="1" flexDirection={["column", "column", "row"]}>
        <Box
          width={[1, 1, 262]}
          height={[103, 103, 0]}
          sx={{ zIndex: [10, 10, 0] }}
        >
          <Box
            width={[1, 1, 262]}
            p={[2, 2, 4]}
            sx={{
              position: ["fixed"],
              top: [0],
              left: [0],
              bottom: [null, null, 0],
              background: `url("${background}") center center / cover no-repeat`,
            }}
            // bg="background"
          >
            <Header />
          </Box>
        </Box>
        <Flex flex="1" as="main" sx={{ position: "relative" }}>
          {children}
        </Flex>

        <Box height={[67, 67, 0]}>
          <Flex
            height={[67, 67, 0]}
            sx={{
              position: "fixed",
              bottom: "0",
              width: "100%",
            }}
          >
            <Link
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                border: "1px solid #D0D0D0",
                background: "#F4EEEA",
                textDecoration: "none",
              }}
              href="https://www.google.com/maps/dir//OC+Opatovsk%C3%A1+1753%2F12+Praha+11.NP+pinkemely/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x470b93c3be21c325:0xa9192b82484bccb?sa=X&ved=2ahUKEwjpmoTDrPPoAhVDwMQBHZ6vDEEQ9RcwE3oECBkQEQ"
              target="_blank"
            >
              <LocationIcon height="30" />
              <Text
                as="p"
                fontFamily="body"
                fontWeight="bold"
                fontSize={1}
                lineHeight="body"
                color="text"
                mt={1}
              >
                MAPA
              </Text>
            </Link>

            <Link
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                border: "1px solid #D0D0D0",
                background: "#F4EEEA",
                textDecoration: "none",
              }}
              href="tel:+420-777-022-416"
            >
              <PhoneIcon height="30" color="#31261D" />
              <Text
                as="p"
                fontFamily="body"
                fontWeight="bold"
                fontSize={1}
                lineHeight="body"
                color="text"
                mt={1}
              >
                TELEFON
              </Text>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Div100vh>
  </Theme>
)

export default Layout
