import React from "react"
import { ThemeProvider } from "styled-components"

export const theme = {
  breakpoints: ["40em", "52em", "64em", "75em", "87.5em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    text: "#31261D",
    textInverse: "#fff",
    background: "#F4BFCF",
    accent: "#8E1F62",
    accentOnDark: "#8E1F62",
    accentPale: "#fff",
    gray: "#53463C",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "Open Sans, sans-serif",
    text: "Open Sans,sans-serif",
    heading: "Catalina Anacapa, sans-serif",
  },
  fontWeights: {
    light: 300,
    body: 400,
    semiBold: 500,
    bold: 600,
    heading: 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
    },
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
