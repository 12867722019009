import styled from "styled-components"

export const Link = styled.a`
  display: block;
  padding: ${({ theme: { space } }) => space[2]}px;
  color: ${({ theme: { colors } }) => colors.textInverse};
  font-family: ${({ theme: { fonts } }) => fonts.heading};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[5]}px;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.heading};
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;

  &:hover {
    color: ${({ theme: { colors } }) => colors.accentOnDark};
  }
`

export const Text = styled.span`
  color: ${({ theme: { colors } }) => colors.accentPale};
  font-family: ${({ theme: { fonts } }) => fonts.body};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[1]}px;
`
