import { createGlobalStyle } from "styled-components"
import styledNormalize from "styled-normalize"

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
  }

  p {
    font-family: ${({ theme: { fonts } }) => fonts.body}px;
    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
  }

  button {
    background: none;
    box-shadow: none;
    border: none; 
  }

  #SRLLightbox {
    z-index: 9999
  }
`

export default GlobalStyle
