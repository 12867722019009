import React, { useState } from "react"
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navigate } from "@reach/router"
import * as Styled from "./styled"
import { Flex, Box, Link as RebassLink } from "rebass/styled-components"
import Instagram from "../../images/svgs/instagram"
import Facebook from "../../images/svgs/facebook"
import Phone from "../../images/svgs/phone"
import Clock from "../../images/svgs/clock"
import Burger from "../../images/svgs/burger"

const Header = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [visible, setVisible] = useState(false)

  return (
    <header>
      <Flex
        justifyContent="center"
        mt={[0, 0, 5]}
        sx={{ position: "relative" }}
      >
        <Box mt={[1, null, 2]} sx={{ textAlign: "center" }}>
          <GatsbyLink to="/" style={{ display: "block" }}>
            <Img fixed={logo.childImageSharp.fixed} />
          </GatsbyLink>
        </Box>
        <Flex
          alignItems="center"
          sx={{
            display: [null, null, "none"],
            position: "absolute",
            right: "0",
            cursot: "pointer",
          }}
        >
          <button onClick={() => setVisible(!visible)}>
            <Burger />
          </button>
        </Flex>
      </Flex>

      <Box
        height={[visible ? "100vh" : 0, null, "auto"]}
        sx={{
          overflow: ["hidden", null],
          transition: [".2s", null],
          textAlign: "center",
        }}
      >
        {/* Menu */}
        <Box py={4}>
          <Styled.Link
            href="/"
            onClick={e => {
              e.preventDefault()
              setVisible(false)
              console.log(window.innerWidth)
              setTimeout(() => navigate("/"), window.innerWidth < 831 ? 200 : 0)
            }}
          >
            Úvod
          </Styled.Link>
          <Styled.Link
            href="/about"
            onClick={e => {
              e.preventDefault()
              setVisible(false)
              setTimeout(
                () => navigate("/about"),
                window.innerWidth < 831 ? 200 : 0
              )
            }}
          >
            O nás
          </Styled.Link>
          <Styled.Link
            href="/menu"
            onClick={e => {
              e.preventDefault()
              setVisible(false)
              setTimeout(
                () => navigate("/menu"),
                window.innerWidth < 831 ? 200 : 0
              )
            }}
          >
            Menu
          </Styled.Link>
          <Styled.Link
            href="/gallery"
            onClick={e => {
              e.preventDefault()
              setVisible(false)
              setTimeout(
                () => navigate("/gallery"),
                window.innerWidth < 831 ? 200 : 0
              )
            }}
          >
            Galerie
          </Styled.Link>
          <Styled.Link
            href="/contact"
            onClick={e => {
              e.preventDefault()
              setVisible(false)
              setTimeout(
                () => navigate("/contact"),
                window.innerWidth < 831 ? 200 : 0
              )
            }}
          >
            Kontakt
          </Styled.Link>
        </Box>
        {/* Social */}
        <Flex
          justifyContent="center"
          py={2}
          sx={{
            borderTop: "1px solid #F2EBEB",
            borderBottom: "1px solid #F2EBEB",
          }}
        >
          <RebassLink
            mx={2}
            target="_blank"
            href="https://www.instagram.com/pinkemelycafe"
          >
            <Instagram width style={{ width: 40 }} />
          </RebassLink>
          <RebassLink
            mx={2}
            target="_blank"
            href="https://www.facebook.com/pinkemely/"
          >
            <Facebook />
          </RebassLink>
        </Flex>
        {/* Additional Info */}
        <Box
          py={2}
          sx={{
            display: ["none", null, "block"],
            textAlign: "left",
          }}
        >
          <Flex alignItems="center" mx={2} m={2}>
            <Box mr={2}>
              <Phone />
            </Box>
            <Styled.Text>+420 777 022 416</Styled.Text>
          </Flex>
          <Flex alignItems="center" mx={2}>
            <Box mr={2}>
              <Clock />
            </Box>
            <Box>
              <Box>
                <Styled.Text>Po - Pa 09:00 - 20:00</Styled.Text>
              </Box>
              <Box>
                <Styled.Text>So - Ne 10-00 - 20:00</Styled.Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </header>
  )
}

export default Header
